.project-section-container {
    padding: 3vh 0;
    width: 100%;
    background-color: var(--custom-primary);
}

.project-section {
    width: 100%;
    margin: 0 auto;
}

.project-section h1 {
    text-align: center;
}

.project-section h1 {
    color: white;
}

.project-section h4, .project-section p, .project-section h5{
    color: black;
}

.project-section .project-section-col {
    padding: 0 0 5vh 0;
}

.project-section .card {
    background-color: var(--custom-secondary);
    margin: 3vh;
    border: none;
    height: 100%;
    transition: 0.3s;
}

.project-section .card:hover {
    box-shadow: 0 1vh 3vh var(--custom-background);
}

.project-section .empty-card {
    background-color: transparent;
    border: 0 ;
}

.project-section .card-img {
    border-radius: 0;
    height: 350px;
    object-fit: cover;
    object-position: top;
}

.project-skills {
    color: var(--custom-gray);
    display: flex;
    justify-content: flex-start;
}
.skill-icon {
    color: white;
}

.project-skills .skill-icon {
    padding-left: 2vh;
    color: black;
}

.project-section a {
    color: var(--custom-background);
}

.project-card-link {
    text-decoration: none;
}
