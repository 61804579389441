.loadable-component {
    text-align: center;
    vertical-align: middle;
}
html {
    background-color: var(--loading-background);
}

.css-0 {
    margin:45vh auto;
    width: fit-content;
    display: flex;
}

body {
    height: fit-content;
}