@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap');

.frontPage {
    background-color: var(--custom-background);
    font-family: 'Nanum Gothic', sans-serif;
}

.blueTimelineElements {
    color: white;
}

.skill-section {
    text-align: center;
}

.ant-timeline-item-head {
    background-color: transparent;
}

.container-fluid {
    padding: 0;
}