.header-img-text-row{
    background-position: 25% 80%;
    background-size: cover;
    height: 100vh;
    width: 100%;
    margin: 0;
    color: white;
}

.header-img-text-box {
    background-color: rgb(0,0,0, 0.50);
    padding: 25px;
    margin-top: 40vh;
    text-align: center;
    border-radius: 10px
}

.blog-button {
    padding: 10px;
}

.blog-button-icon {
    display: unset;
    padding: 0 5px 0 0 ; 
}

@media (max-width: 765px) {
    .header-img-text-row {
        height: 80vh;
    }
    .header-img-text-box {
        margin-top: 15vh;
        text-align: center;
    }
}