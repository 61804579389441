.collapse-button {
    width: fit-content;
    margin: 0 auto;
    padding-bottom: 3vh;

}

.collapse-button button, .collapse-button button:focus {
    background-color: var(--custom-primary);
    border: var(--custom-primary);
}

.collapse-button button:hover {
     background-color: var(--custom-secondary) !important;
     border: var(--custom-secondary) !important;
}


.collapse .row {
    width: 100%;
    margin: 0;
}