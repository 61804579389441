.about-section {
    text-align:center;
    margin-top: 5vh;
}

.about-section h1, .about-section h2, .about-section h3, .about-section h4, .about-section h5, .about-section h6, .about-section p{
    color: white;
}

.about-section h5 {
    text-align: left;
    padding-top: 2vh;
    font-size: 1.1rem;
}

.about-section h2 {
    padding-top: 3vh;
}
.about-section p {
    padding-top: 5vh;
}

.about-section .row {
    width: 100%;
    margin: 0;
}

.about-image {
    display:flex;
    align-items: center;
    justify-content: center;
}